<template>
  <v-main :class="'bg '+ ($route.name=='login' ? 'login-bg' : '')">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-main>
</template>

<script>
export default {
  name: "BaseView",
};
</script>

<style>
@keyframes rotate {
  0% {transform: translate(-50%, 0) rotateZ(0deg);}
  50% {transform: translate(-50%, -2%) rotateZ(180deg);}
  100% {transform: translate(-50%, 0%) rotateZ(360deg);}
}  
.login-bg>div{
  z-index: 999;
}
.login-bg {
  background: url("/bg.jpg");
  background-size: 100% 100%;
  max-width: inherit!important;
}
.login-bg:before, .login-bg:after {
  content: "";
  position: absolute;
  left: 50%;
  min-width: 300vw;
  min-height: 300vw;
  background-color: #fcfff538;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.login-bg:before {
  bottom: 30vh;
  border-radius: 45%;
  animation-duration: 20s;
}

.login-bg:after {
  bottom: 30vh;
  opacity: .5;
  border-radius: 47%;
  animation-duration: 30s;
}
.v-main.bg {
  /* background-color: #f2f5f8; */
  background-color: rgba(255, 255,255, 0.9);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* overflow: auto; */
}
.v-main.bg>div {
  padding: 30px 30px;
}
.v-application--wrap {
  background: url(/bg6.jpg);
  background-size: cover;
}

</style>